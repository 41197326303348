import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { CreateLLMQuestionResponse } from '../../queries/gpt/types';

const useStyles = makeStyles(() => ({
  input: {
    transform: 'scale(1.5)',
  },
  inputLabel: {
    transform: 'scale(1.5)',
    marginLeft: '10px',
  },
  answerBox: {
    backgroundColor: '#f1f1f1',
    padding: '10px',
  },
}));

interface LLMQuestionProps {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  question: CreateLLMQuestionResponse;
}

const LLMQuestion: React.FC<LLMQuestionProps> = ({
  setInputValue,
  question,
}: LLMQuestionProps): ReactElement => {
  const classes = useStyles();
  const handelCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(() => '');
    if (event.target.checked) {
      setInputValue((value: string) => `${question.question}${value} ${event.target.value}`);
    }
  };
  return (
    <Box className={classes.answerBox}>
      <Box>
        {question.llmQuestion}
      </Box>
      { question.suggestions.map((suggestion) => (
        <Box key={suggestion}>
          <input
            className={classes.input}
            type="radio"
            id={suggestion}
            name="suggestion"
            value={suggestion}
            onClick={handelCheckboxChange}
          />
          <label className={classes.inputLabel} htmlFor={suggestion}>{suggestion}</label>
        </Box>

      )) }
    </Box>
  );
};

export default LLMQuestion;
